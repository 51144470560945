
body {

  background-color: var(--white);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

.container {

  background:  var(--white);
  padding: 0;
  border-radius: 7px;
}

.profile-image {
  width: 50px;
  height: 50px;
  border-radius: 40px;
}


.search-box-chat {
  background: #fafafa;
  padding: 10px 13px;

  .input-wrapper {
    background:  var(--white);
    border-radius: 40px;

    i {
      color: grey;
      margin-left: 7px;
      vertical-align: middle;
    }
  }
}

input {
  &.file-input {
    border: none;
    border-radius: 30px;
    width: 80%;
  
    &::placeholder {
      color: #e3e3e3;
      font-weight: 300;
      margin-left: 20px;
    }
  
    &:focus {
      outline: none;
    }
  }
}



hr {
  margin: 5px auto;
  width: 60%;
}

.chat-panel {
  background-color: #f2f7f8;
  overflow-y: scroll;
  height: 79vh;
  padding: 30px;
}

.chat-panel.mobile {
  background-color: #f2f7f8;
  overflow-y: scroll;
  height: 75vh;
  padding: 10px;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}



.chat-box-tray {
  background:  var(--white);
  display: flex;
  align-items: baseline;
  padding: 5px 5px;
  align-items: center;
  margin-top: 2px;
  border-radius: 7px;
  bottom: 10 !important;

  input {
    margin: 0 10px;
    padding: 6px 2px;
  }

  i {
    color: grey;
    font-size: 30px;
    vertical-align: middle;

    &:last-of-type {
      margin-left: 25px;
    }
  }
}
.chat-area{
  width: 100%;
  position: relative;
}
.container-text {
  background-color:  var(--white);
  bottom: 0px;
  margin-top: 20px;
  width: 98%;
  margin-left: 12px;

}

.btn-light {

  color:  gray !important;;
}
.text-message{
  border: none;
}


.mobile-header{
  padding-top: 2px;
  padding-left: 10px;
  padding-bottom: 2px;
  background-color: var(--white);
  display: inline-flex;
  width: 100%;

  .back{
    width: 30px;
    height: 40px;
    color: #1485f6;
    align-self: center;
  }
}

button {
  &.btn-attach, &.btn-submit {
    background-color: transparent;
    border-color: transparent;
  }
}