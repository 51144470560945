.chat-card {
  padding: 10px 15px;
  display: flex;
  vertical-align: baseline;
  background: #fff;
  transition: .3s ease;
  border-bottom: 1px solid rgb(241, 244, 247);

  .text {
    margin-left: 12px;
    width: 70%;
    text-decoration: none;
    color: black;
    h6 {
      margin-top: 6px;
      margin-bottom: 0;
    }

    p {
      margin: 0;
    }
  }

  .time {
    color: grey;
  }

  &--onhover:hover {
    background: #f2f7f8;
    cursor: pointer;

  }

  &--selected {
    background-color: rgba(45, 136, 255, 0.1);
  }

  .initials{
    background-color: #f66292;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 50px;
    height: 50px;
    border-radius: 50%;
    font-size: 12px;
    color: white;
  }
}
