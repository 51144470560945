.header, .empty-message {
  padding-top: 20px;
  padding-left: 20px;
  padding-bottom: 10px;
  background-color: var(--white);
  h2{
    color:  var(--black);
  }

}

.empty-message {
  text-align: center;
}

.link {
  text-decoration: none !important;
}

.input-search-mobil {
  padding: 0px 0px 10px 15px;
}