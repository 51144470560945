.chat-bubble{
  background-color: #fdfdfd;
  padding: 6px 14px;
  border-radius: 15px 15px 15px 3px;
  max-width: 300px;
  word-wrap: break-word;
  .img-msg{
    max-width: 100%;
    max-height: 100%;
  }
  margin-bottom: 0px;
}

.chat-time{
  font-size: 12px;
  color:var( --ligth-grey);
  text-align: right;
  margin-left: auto;
}
.chat-time.current-user {
  font-size: 12px;
  color:var(  --ligth-grey);
  text-align: left;
  margin-left: auto;

}
p.current-user{
  text-align: end;
}
.chat-bubble.current-user {
  margin-left: auto;
  background-color: var( --bubble-chat-user);
  border-radius: 15px 15px 3px 15px;
  color: var(--white);
}

.no-gutters {
  padding: 0;
}