.settings-tray {
    background:  var(--white);
    padding: 10px 15px;
    width: 100%;
    .no-gutters {
      padding: 0;
    }

}

.friend-drawer {
  padding: 10px 15px;
  display: flex;
  vertical-align: baseline;
  background:  var(--white);
  transition: .3s ease;


  .text {
    margin-left: 12px;
    width: 70%;

    h6 {
      margin-top: 6px;
      margin-bottom: 0;
    }

    p {
      margin: 0;
    }
  }

  .time {
    color: var(--ligth-grey);
  }

  &--onhover:hover {

    cursor: pointer;

    p,
    h6,
    .time {
      color:  var(--white) !important;
    }
  }

  .initials{
    background-color: #f66292;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 50px;
    height: 50px;
    border-radius: 50%;
    font-size: 12px;
    color: white;

  }
  .initials.mobile{
      border-radius: 50%;
      width: 50px;
      height: 50px;
      text-align: center;
      color:  var(--white);
      padding: 4%;
    }
}