.chat-container{
  height: 100vh;
  width: 100%;
  background-color: #282c34 ;
}
* {
  box-sizing: border-box;
}

.chat-row{
  margin-right: 0px !important;
  height: 100%;
}
h2{
  color: var(--white);
}
.chat-list{
  background-color:  var(--white);
  overflow-y: scroll;
  height: 100vh;

}
.chat-space{
  padding-right: 0px !important;
  background-color: #f2f7f8;
  padding-left: 0px!important
}

div {
  .input-search {
    padding: 15px 0px 15px 15px;
  }
}

